import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';  // Importe la fonction de scroll de react-scroll
import { useEffect, lazy, Suspense } from 'react';  // Importe useEffect, lazy et Suspense depuis React
import { AnimatePresence } from 'framer-motion';  // Importe AnimatePresence depuis framer-motion
import NotFound from './components/NotFound/NotFound';  // Importe le composant NotFound depuis le chemin spécifié

// Lazy loading des composants pour améliorer les performances
const Home = lazy(() => import('./pages/Home'));  // Composant Home chargé de manière asynchrone
const ProjectDetail = lazy(() => import('./pages/ProjectPage/ProjectDetail'));  // Composant ProjectDetail chargé de manière asynchrone
const Mention = lazy(() => import('./pages/mention'));  // Composant Mention chargé de manière asynchrone

// Composant ScrollToTop pour remonter en haut de la page à chaque changement de route
function ScrollToTop() {
  const { pathname } = useLocation();  // Obtient le chemin actuel de l'URL avec useLocation()

  // Utilisation de useEffect pour déclencher le scroll vers le haut à chaque changement de pathname
  useEffect(() => {
    scroll.scrollToTop({
      duration: 0,  // Définit la durée du scroll à 0 pour un défilement instantané
    });
  }, [pathname]);  // Déclenche l'effet à chaque changement de pathname

  return null;  // ScrollToTop ne rend rien (composant fonctionnel)
}

// Composant principal de l'application
function App() {
  return (
    <Router>  {/* Utilisation du Router pour gérer la navigation */}
      <ScrollToTop /> {/* Composant ScrollToTop pour remonter en haut à chaque changement de route */}
      <AnimatePresence mode='wait' initial={false}>  {/* Utilisation d'AnimatePresence pour gérer les animations de manière fluide */}
        <Suspense fallback={<div>Loading...</div>}>  {/* Suspense pour gérer le chargement asynchrone des composants */}
          <Routes>  {/* Conteneur pour toutes les routes de l'application */}
            <Route path="/" element={<Home />} />  {/* Route pour la page d'accueil */}
            <Route path="/project/:id" element={<ProjectDetail />} />  {/* Route pour les détails d'un projet avec un paramètre :id */}
            <Route path="/mentionslegales" element={<Mention />} />  {/* Route pour la page de mentions légales */}
            <Route path="/*" element={<NotFound />} />  {/* Route pour toutes les autres URL non définies */}
          </Routes>
        </Suspense>
      </AnimatePresence>
    </Router>
  );
}

export default App;  // Exporte le composant App comme composant par défaut
